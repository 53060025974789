@tailwind base;
@tailwind components;
@tailwind utilities;

body {}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}
